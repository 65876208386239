// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  region: "",
  ClientId: '',
  credentials: {
    accessKeyId: "",
    secretAccessKey: ""
  },
  UserPoolId: '',
  cmsUri: '',
  cmsToken: '',
  dataProxy: 'https://sense-api.koreda.app/api/data-proxy/query?query=',
  contactApi: 'https://sense-api.koreda.app/api/contact-us',
  dailyReportUrl: 'https://daily-report.koreda.app/{2}?date={0}&t={1}',
  dailyReportHistUrl: 'https://sense-api.koreda.app/api/daily-report/hist/{2}?date={0}&t={1}&dataupdate={3}',
  dailyCategoryPackageReportUrl: 'https://sense-api.koreda.app/api/category-package-report/Query?date={date}',
  dailyCategoryPackageCheckFileReportOnS3Url: 'https://sense-api.koreda.app/api/snowflake-proxy/CheckCategoryPackageReportExistOnS3?date={date}',
  snowflakeProxy:'https://sense-api.koreda.app/api/snowflake-proxy/query',
  hygraphGetCustomer:'https://sense-api.koreda.app/api/hygraph/GetCustomerQuery',
  hygraphGetJobUpdate:'https://sense-api.koreda.app/api/hygraph/GetJobUpdate',
  hygraphGetSEJobUpdate:'https://sense-api.koreda.app/api/hygraph/GetSEJobUpdate',
  hygraphGetTraxJobUpdate:'https://sense-api.koreda.app/api/hygraph/GetPhotoRecognitionJobUpdate',
  hygraphGetCategoryPackageJobUpdate:'https://sense-api.koreda.app/api/hygraph/GetCategoryPackageJobUpdate',
  hygraphGetMenus:'https://sense-api.koreda.app/api/hygraph/GetMenusQuery',
  hygraphGetUsers:'https://sense-api.koreda.app/api/hygraph/GetUsers',
  hygraphGetComment:'https://sense-api.koreda.app/api/hygraph/GetComment',
  hygraphCreateComment:'https://sense-api.koreda.app/api/hygraph/CreateComment',
  hygraphUpdateComment:'https://sense-api.koreda.app/api/hygraph/UpdateComment',
  hygraphDeleteComment:'https://sense-api.koreda.app/api/hygraph/DeleteComment',
  hygraphCommentPermission:'https://sense-api.koreda.app/api/hygraph/GetCommentPermission',
  hygraphGetNotification:'https://sense-api.koreda.app/api/hygraph/GetNotification',
  commentsendemail:'https://sense-api.koreda.app/api/email/index',
  snowflakeProxyV2 : 'https://sense-api.koreda.app/api/v2/snowflake-proxy/query',
  notificationApi: 'https://sense-api.koreda.app/api/notification',
  saveReportPdf: 'https://sense-api.koreda.app/api/ping/SavePdfFile',
  clientId: "88695939-45ad-4345-a11d-72f0237d8d67",
  authority: "https://login.microsoftonline.com/52f958ed-e366-4ad5-bdcf-9115f6e29b35",
  authenserver: "api://88695939-45ad-4345-a11d-72f0237d8d67/authenserver",
  redirectUri: 'https://sense.koreda.app/processing',
  customer: 'customersProd',
  clearCacheJobStatus: 'clearCacheJobProds',
  clearCategoryPackageCacheJobStatus: 'clearCategoryPackageCacheJobProds',
  comment: 'commentsProd',
  clearCacheSEJobStatus: 'clearCacheSEJobProds',
  clearCacheTraxJobStatus: 'clearCachePhotoRecognitionJobProds',
  menuSection: 'menuSections',
  cmsEnv: 'PRD'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
