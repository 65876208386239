import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from '../intage_helper';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { DropdownValueToNgSelectFormat2 } from 'src/app/helpers/dropdown';
import { getSelectedWeekFromUrl } from 'src/app/helpers/queryString';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
@Component({
  selector: 'app-intage_dashboard',
  templateUrl: './intage_dashboard.component.html',
  styleUrls: ['./intage_dashboard.component.scss']
})
export class IntageDashboardComponent implements OnInit {
  intageData: any;
  intageDataSankey: any;
  weeks: any;
  selectedWeek: string;
  intageMonthData: any;
  categoryColors: any;
  channelOrder: any;
  isScoreCardsReady: boolean;
  isOtcValueVsLyReady: boolean;
  isValueShareTimelineReady: boolean;
  isShareVsLyReady: boolean;
  isSankeyReady: boolean;
  constructor(private router: Router,public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  async ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
      this.categoryColors = IntageHelper.CategoryColors();
      this.channelOrder = IntageHelper.ChannelOrders();
      this.weeks = await IndexDbHelper.Get('IntageDashboardWeeks');
      IntageHelper.GetIntageDashboardWeekData(this.http).then(async (weeks) => {
        if (this.weeks == null || this.weeks.length != weeks.length)
        {
          this.weeks = [...new Set(weeks.map(x=> x.week))];
          IntageHelper.GetIntageDataDashboard(this.http).then((intage) => {        
            IndexDbHelper.Set('IntageDashboardWeeks', this.weeks);
            let weekList = this.weeks.map(x => "'" + x + "'");
            IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
              this.intageMonthData = intageMonthData;
              IndexDbHelper.Set('IntageDashboardMonthData', intageMonthData);
            });
            if(this.selectedWeek == null || this.selectedWeek == ''){
              this.selectedWeek = this.weeks[0] as string;
            }
            this.intageData = intage;
            IndexDbHelper.Set('IntageData', intage);
          });
        }
        else
        {
          if(this.selectedWeek == null || this.selectedWeek == ''){
            this.selectedWeek = this.weeks[0] as string;
          }

          let weekList = this.weeks.map(x => "'" + x + "'");
          IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
            this.intageMonthData = intageMonthData;
            IndexDbHelper.Set('IntageDashboardMonthData', intageMonthData);
          });
          
          // this.intageData = await IndexDbHelper.Get('IntageData');
          // if (this.intageData == null)
          // {
            IntageHelper.GetIntageDataDashboard(this.http).then((intage) => {
              this.intageData = intage;
              IndexDbHelper.Set('IntageData', intage);
            })
          //}
        }
    })

    const selectedWeek = getSelectedWeekFromUrl(this.router);
    if (selectedWeek) {
      this.selectedWeek = selectedWeek;
      this.onSelectedWeekChanged(this.selectedWeek);
    }

  }
}
  
  public otcValueVsLyReady(ready: boolean){
    this.isOtcValueVsLyReady = ready;
  }
  public scoreCardsReady(ready: boolean){
    this.isScoreCardsReady = ready;
  }
  public valueShareTimelineReady(ready: boolean){
    this.isValueShareTimelineReady = ready;
  }
  public shareVsLyReady(ready: boolean){
    this.isShareVsLyReady = ready;
  }
  public sankeyReady(ready: boolean){
    this.isSankeyReady = ready;
  }
  public onSelectedWeekChanged(selectedWeek: string){
    this.selectedWeek = selectedWeek;
    this.isSankeyReady = false;
    this.isShareVsLyReady = false;
    this.isScoreCardsReady = false;
    this.isOtcValueVsLyReady = false;
    this.isValueShareTimelineReady = false;
  }

  dropdownValueToNgSelectFormat(value) {
    return DropdownValueToNgSelectFormat2(value)
  }
  
}
