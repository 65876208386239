import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from '../intage_helper';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { DropdownValueToNgSelectFormat2 } from 'src/app/helpers/dropdown';
import { getSelectedWeekFromUrl } from 'src/app/helpers/queryString';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
@Component({
  selector: 'app-intage_vm_dashboard',
  templateUrl: './intage_vm_dashboard.component.html',
  styleUrls: ['./intage_vm_dashboard.component.scss']
})
export class IntageVMDashboardComponent implements OnInit {
  intageData: any;
  intageDataSankey: any;
  weeks: any;
  selectedWeek: string;
  intageMonthData: any;
  categoryColors: any;
  channelOrder: any;
  isScoreCardsReady: boolean;
  isOtcValueVsLyReady: boolean;
  isValueShareTimelineReady: boolean;
  isShareVsLyReady: boolean;
  isSankeyReady: boolean;
  constructor(private router: Router,public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  async ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
       //AnalyticsHelper.openSweetAlert(`<p class="line1">Important Notice</p><p class="line2">Intage data and definitions are being updated. SENSE updates and email distribution will resume on February 20th. Check the URL below for the latest report with the new category definition.</p>
         //<a class="line2" href="https://ccbji.sharepoint.com/sites/MarketCommercialInsightShareSite2/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FMarketCommercialInsightShareSite2%2FShared%20Documents&viewpath=%2Fsites%2FMarketCommercialInsightShareSite2%2FShared%20Documents%2FForms%2FAllItems%2Easpx&xsdata=MDV8MDJ8cmFzb29sLmFnaGRhbUBjY2JqaS5jby5qcHw1ZWNiZDk1ZGI3MmM0YWFkZTA1MzA4ZGQ0NzRhOGQ3NXw1MmY5NThlZGUzNjY0YWQ1YmRjZjkxMTVmNmUyOWIzNXwwfDB8NjM4NzQ1MTA3NzE4MTk2OTkwfFVua25vd258VFdGcGJHWnNiM2Q4ZXlKRmJYQjBlVTFoY0draU9uUnlkV1VzSWxZaU9pSXdMakF1TURBd01DSXNJbEFpT2lKWGFXNHpNaUlzSWtGT0lqb2lUV0ZwYkNJc0lsZFVJam95ZlE9PXwwfHx8&sdata=TElKRTdHR1BFd1F6RjVPZDkxeWtvTmV3Mno2SWJZQmhmNHl3UlgrL1JRND0%3D&id=%2Fsites%2FMarketCommercialInsightShareSite2%2FShared%20Documents%2Fレポート%2F07%5FWeekly%20Share%20Report%2FWeekly%20Share%20Report%2FFlash%20Report%2F202502&viewid=df78316a%2Da505%2D4c8d%2D87aa%2D5cdce432fdb3">LINK to Excel Report</a>`, false);
      this.categoryColors = IntageHelper.CategoryColors();
      this.channelOrder = IntageHelper.ChannelOrders();
      this.weeks = await IndexDbHelper.Get('IntageVmDashboardWeeks');
      IntageHelper.GetIntageVMWeekData(this.http).then(async (weeks) => {
        if (this.weeks == null || this.weeks.length != weeks.length)
        {
          this.weeks = [...new Set(weeks.map(x => x.week))];
          IntageHelper.GetIntageVMData(this.http).then((intage) => {      
            IndexDbHelper.Set('IntageVmDashboardWeeks', this.weeks);
            let weekList = this.weeks.map(x => "'" + x + "'");
            IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
              this.intageMonthData = intageMonthData;
              IndexDbHelper.Set('IntageVmDashboardMonthData', intageMonthData);
            });
            if(this.selectedWeek == null || this.selectedWeek == ''){
              this.selectedWeek = this.weeks[0] as string;
            }
            this.intageData = intage;
            IndexDbHelper.Set('IntageVmData', intage);
          });
        }
        else
        {
          if(this.selectedWeek == null || this.selectedWeek == ''){
            this.selectedWeek = this.weeks[0] as string;
          }
  
          let weekList = this.weeks.map(x => "'" + x + "'");
          IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
            this.intageMonthData = intageMonthData;         
            IndexDbHelper.Set('IntageVmDashboardMonthData', intageMonthData);
          });
          
          // this.intageData = await IndexDbHelper.Get('IntageVmData');
          // if (this.intageData == null)
          // {
            IntageHelper.GetIntageVMData(this.http).then((intage) => {
              this.intageData = intage;
              IndexDbHelper.Set('IntageVmData', intage);
            })
          //}
        }
      })

      const selectedWeek = getSelectedWeekFromUrl(this.router);
      if (selectedWeek) {
        this.selectedWeek = selectedWeek;
        this.onSelectedWeekChanged(this.selectedWeek);
      }

  }
}
  
  public otcValueVsLyReady(ready: boolean){
    this.isOtcValueVsLyReady = ready;
  }
  public scoreCardsReady(ready: boolean){
    this.isScoreCardsReady = ready;
  }
  public valueShareTimelineReady(ready: boolean){
    this.isValueShareTimelineReady = ready;
  }
  public shareVsLyReady(ready: boolean){
    this.isShareVsLyReady = ready;
  }
  public sankeyReady(ready: boolean){
    this.isSankeyReady = ready;
  }
  public onSelectedWeekChanged(selectedWeek: string){
    this.selectedWeek = selectedWeek;
    this.isSankeyReady = false;
    this.isShareVsLyReady = false;
    this.isScoreCardsReady = false;
    this.isOtcValueVsLyReady = false;
    this.isValueShareTimelineReady = false;
  }

  dropdownValueToNgSelectFormat(value) {
    return DropdownValueToNgSelectFormat2(value)
  }
  
}
